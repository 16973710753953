import './candy-heart.css';
import {CandyHeart} from "./CandyHeart";
import "./candy-heart.css"
import {useEffect, useState} from "react";
import Arrow from "./Asset 5.png"

document.addEventListener('contextmenu', event => event.preventDefault());

export const HeartPage = () => {

    const orange = "#FFB465"
    const green = "#BBE66B"
    const red = "#FF7176"
    const purple = "#B844EB"
    const blue = "#71C7EC"

    const initialMessages1 = [
        'BIG FAN',
        'PROUD OF U',
        'PUSH THRU',
        'GO TIME',
        'FEAR LESS',
        'WAY 2 GO',
        'YOUDA BEST',
        'GOOD JOB',
        'BE YOU',
        'GO 4 IT',
        'SUPER STAR',
        'U GOT THIS',
        'CRUSH IT',
        'HIGH FIVE',
        'DON’T QUIT'
    ]
    const initialMessages2 = [
        'WAY 2 GO',
        'CRUSH IT',
        'PROUD OF U',
        'DON’T QUIT',
        'GOOD JOB',
        'PUSH THRU',
        'BE YOU',
        'FEAR LESS',
        'CHIN UP',
        'YOUDA BEST',
        'GO 4 IT',
        'SUPER STAR',
        'U GOT THIS',
        'GO TIME',
        'HIGH FIVE',
        'BIG FAN'
    ]
    const initialMessages3 = [
        'SUPER STAR',
        'CRUSH IT',
        'BIG FAN',
        'YOUDA BEST',
        'PROUD OF U',
        'DON’T QUIT',
        'PUSH THRU',
        'BE YOU',
        'CHIN UP',
        'GO 4 IT',
        'WAY 2 GO',
        'HIGH FIVE',
        'U GOT THIS',
        'GO TIME',
        'FEAR LESS',
    ]

    const initialFlipArrayShort = [false, false, false, false]
    const initialFlipArray = [false, false, false, false, false, false]

    const colors1 = [orange,blue, purple, orange]
    const colorsClass1 = ['orange','blue', 'purple', 'orange']
    const colors2 = [blue, red, green, purple, orange, blue]
    const colorsClass2 = ['blue', 'red', 'green', 'purple', 'orange', 'blue']
    const colors3 = [red,green, purple, orange, blue, red]
    const colorsClass3 = ['red','green', 'purple', 'orange', 'blue', 'red']

    const shuffle = (array) => {
        let currentIndex = array.length, randomIndex;

        // While there remain elements to shuffle.
        while (currentIndex !== 0) {

            // Pick a remaining element.
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }

        return array;
    }

    const flipHeart = (index, array) => {
        if(!flippedArrayShort.includes(true) && !flippedArray1.includes(true) && !flippedArray2.includes(true)){
            console.log("First")
            setEndingTime(1004590800)
            setDone(false)
            setStartTime(Date.now())
        }
        if(flippedArrayShort.filter(bool => bool === false).length + flippedArray1.filter(bool => bool === false).length + flippedArray2.filter(bool => bool === false).length === 1){
            setFinalIndex(index)
            if(flippedArrayShort.filter(bool => bool === false).length === 1){
                setFinalArray(1)
            } else if(flippedArray1.filter(bool => bool === false).length === 1){
                setFinalArray(2)
            } else{
                setFinalArray(3)
            }
        }
        if (array === 1) {
            setFlippedArray1([...flippedArray1.slice(0, index), true, ...flippedArray1.slice(index + 1, flippedArray1.length)])
        } else if(array === 2) {
            setFlippedArrayShort([...flippedArrayShort.slice(0, index), true, ...flippedArrayShort.slice(index + 1, flippedArrayShort.length)])
        } else{
            setFlippedArray2([...flippedArray2.slice(0, index), true, ...flippedArray2.slice(index + 1, flippedArray2.length)])
        }
    }

    const resetHearts = () => {
        setTimeout(()=>{setFlippedArrayShort([false,true,true,true])},100) //0,0

        setTimeout(()=>{setFlippedArrayShort([false,false,true,true])},200) //0,1
        setTimeout(()=>{setFlippedArray1([false, true, true, true, true, true])},200) //0,1

        setTimeout(()=>{setFlippedArray1([false, false, true, true, true, true])},300) //1,1
        setTimeout(()=>{setFlippedArray2([false, true, true, true, true, true])},300) //2,0

        setTimeout(()=>{setFlippedArray1([false, false, false, true, true, true])},400) //1,2
        setTimeout(()=>{setFlippedArray2([false, false, true, true, true, true])},400) //2,1

        setTimeout(()=>{setFlippedArrayShort([false,false,false,true])},500) //0,2
        setTimeout(()=>{setFlippedArray1([false, false, false, false, true, true])},500) //1,3
        setTimeout(()=>{setFlippedArray2([false, false, false, true, true, true])},500) //2,2

        setTimeout(()=>{setFlippedArrayShort([false,false,false,false])},600) //0,3
        setTimeout(()=>{setFlippedArray1([false, false, false, false, false, true])},600) //1,4
        setTimeout(()=>{setFlippedArray2([false, false, false, false, true, true])},600) //2,3

        setTimeout(()=>{setFlippedArray1([false, false, false, false, false, false])},700) //1,5
        setTimeout(()=>{setFlippedArray2([false, false, false, false, false, true])},700) //2,4

        setTimeout(()=>{setFlippedArray2([false, false, false, false, false, false])},800) //2,5
    }

    const [flippedArray1, setFlippedArray1] = useState(initialFlipArray)
    const [flippedArray2, setFlippedArray2] = useState(initialFlipArray)
    const [flippedArrayShort, setFlippedArrayShort] = useState(initialFlipArrayShort)
    const [messages1, setMessages1] = useState(initialMessages1)
    const [messages2, setMessages2] = useState(initialMessages2)
    const [messages3, setMessages3] = useState(initialMessages3)

    const [startTime, setStartTime] = useState(Date.now())
    const [endingTime, setEndingTime] = useState(1004590800)
    const [now, setNow] = useState(Date.now())
    const [done, setDone] = useState(false)

    const [finalIndex, setFinalIndex] = useState(-1)
    const [finalArray, setFinalArray] = useState(-1)

    useEffect(() => {
        setMessages1(shuffle(initialMessages1))
        setMessages2(shuffle(initialMessages2))
        setMessages3(shuffle(initialMessages3))
    }, [])

    useEffect(() => {
        if(!flippedArray1.includes(false) && !flippedArray2.includes(false) && !flippedArrayShort.includes(false)){
            setEndingTime(Date.now())
            setDone(true)
            setTimeout(()=>{resetHearts()},1250)
        }
    }, [flippedArray1,flippedArray2,flippedArrayShort])

    useEffect(()=>{
        if (finalIndex === -1 && finalArray === -1){
            return
        }
        setTimeout(()=>{setFinalArray(-1)},1000)
        setTimeout(()=>{setFinalIndex(-1)},1000)
    },[finalArray, finalIndex])

    useEffect(()=>{
        console.log(startTime)
        console.log(endingTime)
    },[startTime, endingTime])

    useEffect(()=>{
        if(done){
            if (!localStorage.high_score || ((endingTime - startTime)/1000) < localStorage.high_score){
                localStorage.high_score = (endingTime - startTime)/1000
            }
        }
    },[done])

    useEffect(()=>{
        if(startTime === now){
            return
        }
        const interval = setInterval(()=>{
            setNow(Date.now())
        }, 10)
        return () => clearInterval(interval)
    },[startTime])

    return (
        <div className='heart-page'>
            <h1>Valentine’s Day Candy Hearts!</h1>
            {done && <p className={"time"}><b>It took <span>{((endingTime - startTime)/1000).toFixed(3)}</span> seconds to flip all the hearts!</b></p>}
            {!done && <p className={"time running"}><b>{((now - startTime)/1000).toFixed(3)}</b></p>}
            {localStorage.high_score && <p className={"high-score"}><b>The current high score is <span>{localStorage.high_score}</span></b></p>}
            <div className="heart-row-1">
                {flippedArrayShort.map((flip, index) => {
                    return  <div className={index === finalIndex && finalArray === 1 ? 'final-heart' : ''}>
                                <CandyHeart message={messages1[index]} flipped={flippedArrayShort[index]}
                                       onClick={!flippedArrayShort[index] ? () => flipHeart(index, 2): ''} color={colors1[index]} colorClass={colorsClass1[index]}/>
                            </div>
                })}
            </div>
            <div className="heart-row-2">
                {flippedArray1.map((flip, index) => {
                    return  <div className={index === finalIndex && finalArray === 2 ? 'final-heart' : ''}>
                                <CandyHeart message={messages2[index]} flipped={flippedArray1[index]}
                                       onClick={!flippedArray1[index] ? () => flipHeart(index, 1): ''} color={colors2[index]} colorClass={colorsClass2[index]}/>
                            </div>
                })}
            </div>
            <div className="heart-row-3">
                {flippedArray2.map((flip, index) => {
                    return  <div className={index === finalIndex && finalArray === 3 ? 'final-heart' : ''}>
                                <CandyHeart message={messages3[index]} flipped={flippedArray2[index]}
                                onClick={!flippedArray2[index] ? () => flipHeart(index, 3): ''} color={colors3[index]} colorClass={colorsClass3[index]}/>
                            </div>

                })}
            </div>
            <img src={Arrow} alt={"none"} className="ux" />
        </div>
    )
}
