import './candy-heart.css';

export const CandyHeart = (props) => {


    return (
        <div className={!props.flipped ? 'candy-heart' : 'candy-flipped'} onClick={props.onClick}>
            <div className={"flip-card-inner " + props.colorClass}>
                <div className="flip-card-front">
                    <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 224.9 200.46">
                        <g id="Layer_2-2">
                            <path fill={props.color} className="cls-1"
                                  d="M224.9,62.14c0-34.32-27.82-62.14-62.14-62.14-20.4,0-38.51,9.83-49.84,25.02l-.71,.71C100.9,10.36,82.69,.39,62.14,.39,27.82,.39,0,28.22,0,62.54c0,15.32,5.55,29.35,14.74,40.18h-.13l1.05,1.05c1.65,1.86,3.41,3.62,5.28,5.28l91.41,91.41,.3-.3,90.28-90.28,7.16-7.16h-.26c9.4-10.89,15.08-25.07,15.08-40.58Z"/>
                        </g>
                    </svg>
                    <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 224.9 200.46" className={"heart-outline"}>
                        <g id="Layer_2-2">
                            <path fill={"white"} className="cls-1"
                                  d="M224.9,62.14c0-34.32-27.82-62.14-62.14-62.14-20.4,0-38.51,9.83-49.84,25.02l-.71,.71C100.9,10.36,82.69,.39,62.14,.39,27.82,.39,0,28.22,0,62.54c0,15.32,5.55,29.35,14.74,40.18h-.13l1.05,1.05c1.65,1.86,3.41,3.62,5.28,5.28l91.41,91.41,.3-.3,90.28-90.28,7.16-7.16h-.26c9.4-10.89,15.08-25.07,15.08-40.58Z"/>
                        </g>
                    </svg>
                </div>
                <div className="flip-card-back">
                    <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 224.9 200.46">
                        <g id="Layer_2-2">
                            <path fill={props.color} className="cls-1"
                                  d="M224.9,62.14c0-34.32-27.82-62.14-62.14-62.14-20.4,0-38.51,9.83-49.84,25.02l-.71,.71C100.9,10.36,82.69,.39,62.14,.39,27.82,.39,0,28.22,0,62.54c0,15.32,5.55,29.35,14.74,40.18h-.13l1.05,1.05c1.65,1.86,3.41,3.62,5.28,5.28l91.41,91.41,.3-.3,90.28-90.28,7.16-7.16h-.26c9.4-10.89,15.08-25.07,15.08-40.58Z"/>
                        </g>
                    </svg>
                    <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 224.9 200.46" className={"heart-outline"}>
                        <g id="Layer_2-2">
                            <path fill={"white"} className="cls-1"
                                  d="M224.9,62.14c0-34.32-27.82-62.14-62.14-62.14-20.4,0-38.51,9.83-49.84,25.02l-.71,.71C100.9,10.36,82.69,.39,62.14,.39,27.82,.39,0,28.22,0,62.54c0,15.32,5.55,29.35,14.74,40.18h-.13l1.05,1.05c1.65,1.86,3.41,3.62,5.28,5.28l91.41,91.41,.3-.3,90.28-90.28,7.16-7.16h-.26c9.4-10.89,15.08-25.07,15.08-40.58Z"/>
                        </g>
                    </svg>
                    <p>{props.message}</p>
                </div>
            </div>
        </div>
    )
}
